/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #555;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #555;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  #trustwaveSealImage {
    float: left;
    margin: 0 10px 30px 0;
  }
}

/* --------- asc styles --------- */
#header-wrap {
  background-color: $theme-primary;
}
#header {
  a.logo {
    float: left;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    width: 80%;
    max-width: 650px;
    img {
      display: none;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 40px;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.3em;
      font-weight: 500;
      color: #fff;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-weight: 600;
      font-family: 'Roboto Slab', var(--font-family);
    }
    span.small {
      display: none;
      margin-top: 5px;
      font-size: 16px;
      font-family: var(--font-family);
      font-weight: normal;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    color: #fff;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: darken($theme-primary, 15%);
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #fff;
    }
    a.livechat {
      float: right;
      margin: 0 0 0 10px;
      font-weight: normal;
      color: #fff;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}
#banner-wrap {
  border-bottom: 1px solid #777777;
  background: #333 url(../images/banner-bg-sm.jpg) no-repeat 0 0 / cover;
}
.text-shadow {
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
}
.card {
  &.course {
    border: 0;
    border-top: 3px solid #00aebe;
    .best-value {
      background-color: #00aebe;
      padding-top: 2px;
      padding-bottom: 2px;
      text-shadow: 0 1px 1px rgba(0, 0, 0.5);
    }
  }
  &.card-action {
    border: 1px solid #00aebe;
    border-top: 3px solid #00aebe;
  }
}
aside {
  img.asc-logo {
    width: 100%;
    height: auto;
    max-width: 300px;
  }
}

#cta-wrap {
  background: #322a49;
  background: linear-gradient(30deg, #241e38, #382e55 42%, #6b5b8a);
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      display: block;
      float: left;
      max-width: 80px;
      margin-right: 15px;
    }
    span.big {
      display: inline-block;
      font-size: 1.9em;
    }
    span.small {
      display: inline-block;
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header a.logo {
    width: 65%;
    span.small {
      font-size: 18px;
    }
  }
  #header-wrap {
    border-top: 3px solid darken($theme-primary, 15%);
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    // width: 50%;
    span.small {
      font-size: 18px;
    }
  }
  #header #support {
    display: block;
    span.phone {
      display: inline-block;
    }
  }
  #banner-wrap {
    background: #333 url(../images/banner-bg.jpg) no-repeat 0 0 / cover;
    &.business {
      #banner {
        background: url(../images/banner-img.png) no-repeat 10% 100%;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
